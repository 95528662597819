<template>
    <div>
        <st-modal
            id="st_modal_logs_responsibility"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">Logs</p>
            </template>
            <template #body>
                <st-filters-pagination
                    stateModule="responsibilities/logs"
                    :filters="filters"
                    :total="total"
                    @change="doFilter"
                    header="Logs"
                >

                    <slot></slot>
                </st-filters-pagination>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'ResponsibilityLogsModal',
        props: {
            selectedResponsibility: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                rules: {},
                model: {},
                currentPage: 1,
                total: 10,
                fields: {},
                filters: []
            };
        },
        computed: {
            ...mapGetters({}),
        },
        methods: {
            ...mapActions({
                doFetchLogs: 'responsibilities/logs/doFetch',
            }),
            show() {
                this.$refs['modal'].show();
            },
            hide() {
                this.$refs['modal'].hide();
            },
            async doFilter(params) {
                this.doFetchLogs(params);
            },
        },
        mounted() {
            this.$root.$on('bv::modal::shown', (modalObj) => {
                if (modalObj.componentId === 'st_modal_logs_responsibility') {
                    // TODO: TBD once BE is done
                }
            });
        }
    };
</script>
