<template>
    <div>
        <st-button
            v-can:create="'responsibility'"
            variant="secondary"
            :callback="addResponsibility"
        >
            <i class="fa fa-plus"></i>
            {{$t('RESPONSIBILITY.FORM.ADD_TITLE')}}
        </st-button>

        <responsibility-form-modal
            ref="responsibilityFormModal"
            :edit-mode="editMode"
            @updateResposibilities="updateResposibilities"
        > </responsibility-form-modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ResponsibilityFormModal from '@/modules/responsibilities/components/ResponsibilityFormModal.vue';

    export default {
        name: 'ResponsibilitiesListToolbar',
        components: { ResponsibilityFormModal},
        data() {
            return {
                showModal: false,
                editMode: false,
            };
        },
        computed: {
            ...mapGetters({
                record: 'responsibilities/form/record',
                openEditing: 'responsibilities/form/openEditing'
            }),
        },
        watch: {
            openEditing(newValue, oldValue) {
                if (newValue === oldValue || !newValue) return false;
                this.editMode = true;
                this.$refs.responsibilityFormModal.show();
            }
        },
        methods: {
            ...mapActions({
                unselectRecord: 'responsibilities/form/unselect',
            }),
            addResponsibility() {
                this.editMode = false;
                this.$refs.responsibilityFormModal.show();
            },
            updateResposibilities(){
                this.$emit('updateResposibilities');
            }
        }
    };
</script>
