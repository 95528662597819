<template>
    <div>
        <st-modal
            id="st_modal_responsibility"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
            </template>
            <template #body>
                <form class="form"
                    novalidate="novalidate"
                    id="st_modal_responsibility_form"
                >
                    <div class="form-group">
                        <label>
                           {{ fields.name.label }} *
                        </label>
                        <st-input-text
                            ref="name"
                            name="name"
                            v-model="model.name"
                        />
                    </div>
                    <div class="form-group">
                        <label>
                          {{ fields.description.label }}
                        </label>
                        <b-form-textarea
                            ref="description"
                            name="description"
                            v-model="model.description"
                        ></b-form-textarea>
                    </div>
                </form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="doSubmit"
                    >
                        <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
    import { createFormValidation } from '@/shared/utils/create-form-validation';
    import { FormSchema } from '@/shared/form/form-schema';
    import { ResponsibilityModel } from '@/modules/responsibilities/responsabilites-model';

    const { fields } = ResponsibilityModel;
    const formSchema = new FormSchema([
        fields.name,
        fields.description,
    ]);

    import { mapActions, mapGetters, mapState } from 'vuex';

    export default {
        name: 'ResponsibilityFormModal',
        props: {
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                fields,
                filter: '',
                rules: formSchema.rules(),
                model: null,
            };
        },
        computed: {
            ...mapState({
                loading: state => state.shared.loading,
            }),
            ...mapGetters({
                record: 'responsibilities/form/record',
                filters: 'responsibilities/list/filters',
            }),
            headerText() {
                return this.editMode ?
                    this.$t('RESPONSIBILITY.FORM.EDIT_TITLE') :
                    this.$t('RESPONSIBILITY.FORM.ADD_TITLE');
            },
        },
        created() {
            this.model = formSchema.initialValues(this.record);
        },
        mounted() {
            this.$root.$on('bv::modal::shown', (modalObj) => {
                if (modalObj.componentId === 'st_modal_responsibility') {
                    if (this.editMode) {
                        this.model = { ...this.record };
                    }
                    this.fv = createFormValidation('st_modal_responsibility_form', this.rules);
                }
            });
        },
        methods: {
            ...mapActions({
                doCreate: 'responsibilities/form/create',
                doUpdate: 'responsibilities/form/update',
                doFetch: 'responsibilities/list/doFetch',
                unselectRecord: 'responsibilities/form/unselect',
            }),
            show() {
                this.$refs['modal'].show();
            },
            hide() {
                if(this.editMode) this.unselectRecord();
                this.doReset();
                this.$refs['modal'].hide();
            },
            doReset() {
                this.model = formSchema.initialValues(this.record);
            },
            async doSubmit() {
                const validate = await this.fv.validate();
                if (validate === 'Valid') {
                    const { id, ...values } = formSchema.cast(this.model);
                    if (this.editMode) {
                        const payload = {
                            name: this.model.name,
                            description: this.model.description
                        }
                        this.doUpdate({ id, payload }).then(() => {
                            this.$emit('updateResposibilities');
                            this.hide();
                        });
                    } else {
                        this.doCreate(values)
                        .then(() => {
                            this.$emit('updateResposibilities');
                            this.hide();
                        });
                    }
                }
            },
        },
    };
</script>
