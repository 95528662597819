<template>
    <div>
        <responsibility-logs-modal
            ref="responsibilityLogsModal"
            :selectedResponsibility="selectedResponsibility"
            >
        </responsibility-logs-modal>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @edit="onEdit"
            @delete="onDelete"
            @logs="showLogs"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ResponsibilityLogsModal from '@/modules/responsibilities/components/ResponsibilityLogsModal.vue';
    import { ResponsibilityModel } from '@/modules/responsibilities/responsabilites-model';
    const { fields } = ResponsibilityModel;

    export default {
        name: 'ResponsbilitiesListTable',
        components: { ResponsibilityLogsModal },
        data() {
            return {
                presenter: ResponsibilityModel.presenter,
                selectedResponsibility: {},
                actions: [
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    },
                    {
                        name: 'delete',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        disabled: true,
                        type: 'danger'
                    },
                    {
                        name: 'logs',
                        icon: '<i class="fas fa-history"></i>',
                        tooltipText: this.$t('GENERAL.ACTIONS.LOGS'),
                        customIcon: true,
                        disabled: true,
                        type: 'primary'
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'responsibilities/list/rows',
            }),
            fields() {
                return [
                    fields.name,
                    fields.created_date,
                    fields.updated_date,
                    { key:'Actions', label: this.$t('RESPONSIBILITY.FIELDS.ACTIONS')}
                ]
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'responsibilities/form/select',
                deleteRecord: 'responsibilities/form/remove',
            }),
            async onEdit(data) {
               await this.selectRecord(data.item);
            },
            async onDelete(data) {
                await this.deleteRecord(data.item.id);
            },
            showLogs(data) {
                this.selectedResponsibility = data.item;
                this.$refs.responsibilityLogsModal.show();
            }
        },
    };
</script>
