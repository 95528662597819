<template>
    <st-filters-pagination
        stateModule="responsibilities/list"
        :filters="filters"
        :total="total"
        :moreFilters="false"
        @change="doFilter"
        :header="$t('RESPONSIBILITY.FILTER.TITLE')"
    >

        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ResponsibilityModel } from '@/modules/responsibilities/responsabilites-model';

    const { fields } = ResponsibilityModel;
    const filterSchema = new FilterSchema([
        fields.name,
    ]);

    export default {
        name: 'ResponsibilitiesListFilter',
        data() {
            return {
                rules: filterSchema.rules(),
                model: {},
                filters: filterSchema.fields,
            };
        },
        computed: {
            ...mapGetters({
               total: 'responsibilities/list/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'responsibilities/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },

    };
</script>
