<template>
    <st-page
        :title="this.$t('RESPONSIBILITY.FILTER.TITLE')"
    >
        <template #toolbar>
            <responsibilities-list-toolbar @updateResposibilities="updateResposibilities"/>
        </template>
        <responsibilities-list-filter ref="listRespFilter">
            <responsibilities-list-table/>
        </responsibilities-list-filter>
    </st-page>
</template>

<script>
    import ResponsibilitiesListToolbar from '../components/ResponsibilitiesListToolbar';
    import ResponsibilitiesListFilter from '../components/ResponsibilitiesListFilter';
    import ResponsibilitiesListTable from '../components/ResponsibilitiesListTable';

    export default {
        name: 'ResponsibilitiesList',
        components: {
            ResponsibilitiesListToolbar,
            ResponsibilitiesListFilter,
            ResponsibilitiesListTable,
        },
        methods: {
            updateResposibilities() {
                this.$refs.listRespFilter.refresh();
            },
        }
    }
</script>
